import { ReactElement, useEffect } from 'react'
import Logo from '../../assets/logo/logo-dark.svg'
import samaApi from '../../services/SamaApi'

function TestingPage(): ReactElement {
    useEffect(() => {
        const el = document.getElementById('svg')
        if (el) {
            el.remove() // Removes the div with the 'div-02' id
        }
        samaApi.testingEndpoint().catch((e) => {
            throw e
        })
    }, [])
    return (
        <div id="reset-page" className="pink-container bg-pink">
            <img src={Logo} alt="Logo" className="absolute left-8 top-8" />
            <div className="row">
                <div className="col-sm-6 col-md-6">
                    <br />
                    <h2 className="m-t-60">Welcome to the Sama!</h2>
                    <br />
                    <br />
                    <div className="login-form">
                        <p>
                            This is a page to test your connection. Now you have
                            visited this page please inform the person that
                            requested you to visit this link.
                            <br />
                            <br />
                            You may now close this page
                            <br />
                            <br />
                            Thank you for your time.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestingPage
