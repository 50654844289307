import classNames from 'classnames'
import { observer } from 'mobx-react'
import FileIcon from '../../../assets/icons/file-not-sent.svg'
import UrgentIcon from '../../../assets/icons/info-icon.svg'
import UrgentCoachIcon from '../../../assets/icons/urgent_coach.svg'
import { MediaDetails } from '../../../models/response/channel'
import { getFileSize } from '../../../utils/chatHelper'

interface Props {
    mediaDetail: MediaDetails
    isCoachMsg: boolean
    isUrgent: boolean
    message: string | null
}

export const DeletedFileMessage: React.FC<Props> = observer(
    ({ mediaDetail, isUrgent, isCoachMsg }) => {
        return (
            <>
                <div className=" cursor-pointer  inline-flex items-center gap-x-1 justify-between">
                    <div className="flex items-center">
                        <img
                            src={FileIcon}
                            alt="file_img"
                            className="h-9 w-9 mr-1 cursor-pointer"
                        />
                        <div className="flex flex-col mr-6">
                            <span
                                className={classNames(
                                    isCoachMsg && 'text-white',
                                    'font-semibold',
                                )}
                            >
                                {mediaDetail.fileName}
                            </span>
                            <span
                                className={classNames(
                                    isCoachMsg && 'text-white',
                                    'text-[10px]',
                                )}
                            >
                                {getFileSize(mediaDetail.size)}
                            </span>
                        </div>
                    </div>

                    {isUrgent && (
                        <img
                            src={isCoachMsg ? UrgentCoachIcon : UrgentIcon}
                            alt="Urgent"
                            className="h-4 w-4 absolute bottom-0 right-0  mb-1 mr-1"
                        />
                    )}
                </div>
            </>
        )
    },
)
