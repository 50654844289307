import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useCallback } from 'react'
import DownloadIconWhite from '../../../assets/icons/download-white.svg'
import DownloadIcon from '../../../assets/icons/download.svg'
import FileIconWhite from '../../../assets/icons/file-icon-white.svg'
import FileIcon from '../../../assets/icons/file-icon.svg'
import UrgentIcon from '../../../assets/icons/info-icon.svg'
import UrgentCoachIcon from '../../../assets/icons/urgent_coach.svg'
import { MediaDetails } from '../../../models/response/channel'
import { getFileSize } from '../../../utils/chatHelper'
import { useStores } from '../../../utils/stores'

interface Props {
    mediaDetail: MediaDetails
    isCoachMsg: boolean
    isUrgent: boolean
    checkedForMalware: boolean
}

export const FileMessage: React.FC<Props> = observer(
    ({ mediaDetail, isUrgent, isCoachMsg, checkedForMalware }) => {
        const downloadIcon = isCoachMsg ? DownloadIconWhite : DownloadIcon
        const fileIcon = isCoachMsg ? FileIconWhite : FileIcon
        const { channel } = useStores()

        const getTempUrl = useCallback(
            async (mediaSid: string) => {
                if (channel.chatClient) {
                    const urls =
                        await channel.chatClient.getTemporaryContentUrlsForMediaSids(
                            [mediaSid],
                        )
                    const fileUrl = urls.get(mediaSid)
                    return fileUrl
                }
            },
            [channel.chatClient],
        )

        const downloadAction = useCallback(
            async (filename: string) => {
                const url = await getTempUrl(mediaDetail.sid)
                if (url) {
                    fetch(url)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.setAttribute('download', filename)
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            window.URL.revokeObjectURL(link.href)
                        })
                }
            },
            [getTempUrl, mediaDetail.sid],
        )

        const handleFileClick = async () => {
            const url = await getTempUrl(mediaDetail.sid)
            if (url) {
                window.open(url, '_blank')
            }
        }

        return (
            <div className=" cursor-pointer  inline-flex items-center gap-x-1 justify-between">
                <div onClick={handleFileClick} className="flex items-center">
                    <img
                        src={fileIcon}
                        alt="file_img"
                        className="h-6 w-6 mr-1 cursor-pointer"
                    />
                    <div className="flex flex-col mr-6">
                        <span
                            className={classNames(
                                isCoachMsg && 'text-white',
                                'font-semibold',
                            )}
                        >
                            {mediaDetail.fileName}
                        </span>
                        <span
                            className={classNames(
                                isCoachMsg && 'text-white',
                                'text-[10px]',
                            )}
                        >
                            {getFileSize(mediaDetail.size)}
                        </span>
                    </div>
                </div>
                {checkedForMalware && (
                    <img
                        src={downloadIcon}
                        onClick={() => downloadAction(mediaDetail.fileName)}
                        alt="file_img"
                        className="h-3 w-3 mt-2 cursor-pointer"
                    />
                )}

                {isUrgent && (
                    <img
                        src={isCoachMsg ? UrgentCoachIcon : UrgentIcon}
                        alt="Urgent"
                        className="h-4 w-4 absolute bottom-0 right-0  mb-1 mr-1"
                    />
                )}
            </div>
        )
    },
)
