import { IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class AddRoiQuestionModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public answer: string | null = null

    constructor(answer: string | null = null) {
        this.answer = answer
    }
}
