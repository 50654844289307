import { useField } from 'formik'
import React, { useCallback } from 'react'
import { FormValidationMessage } from '../layout/form-validation-message'

interface IProps {
    name: string
}

const Slider: React.FC<IProps> = ({ name }) => {
    const min = 0
    const max = 100
    const step = 10

    const [field, meta, helpers] = useField(name)
    const { value } = field
    const intValue = Number(value)
    const handleSliderChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            helpers.setValue(Number(event.target.value))
        },
        [helpers],
    )

    return (
        <div className="mt-40">
            <div className="w-full mx-auto p-4">
                <div className="mt-4 text-center text-gray-600">
                    {intValue}%
                </div>
                <input
                    type="range"
                    id="slider"
                    min={min}
                    max={max}
                    step={step}
                    value={intValue}
                    onChange={handleSliderChange}
                    className="w-full  rounded-xl appearance-none cursor-pointer accent-white"
                    style={{
                        background: `linear-gradient(to right, #ff7558 ${
                            ((intValue - min) / (max - min)) * 100
                        }%, #ead9d5 ${
                            ((intValue - min) / (max - min)) * 100
                        }%)`,
                    }}
                />
            </div>
            {meta.touched && meta.error && (
                <div className="mt-[10px] error">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}

export default Slider
