import {
    ChatMessage as ChatMessageType,
    IGroup,
} from '../../../models/response/channel'
import { classNames } from '../../../utils/misc'
import { ChatMessage } from './message'

interface Props {
    dailyMessages: IGroup
    date: string
    isCoachMsg: boolean
    coachId: string
    coachimage: string
}

export const MessagesPerMinute = ({
    date,
    isCoachMsg,
    coachId,
    coachimage,
    dailyMessages,
}: Props) => {
    return (
        <div
            className={classNames(
                'w-full relative',
                isCoachMsg && 'ml-[56px] mb-4',
            )}
        >
            {isCoachMsg && (
                <img
                    width="40"
                    height="40"
                    src={coachimage}
                    alt=""
                    className="w-[2.5rem] h-[2.5rem] rounded-[100px] absolute -left-[50px] bottom-[30px]"
                />
            )}

            {dailyMessages[date]
                .filter(
                    (message: ChatMessageType) =>
                        !(message.isDeletedForViruses && isCoachMsg),
                )
                .map((message: ChatMessageType, i: number) => {
                    return (
                        <div className="flex  flex-col" key={i}>
                            <ChatMessage
                                message={message}
                                isCoachMsg={isCoachMsg}
                                coachId={coachId}
                            />
                        </div>
                    )
                })}

            <span
                className={classNames(
                    'text-sm text-[#9d9ca1] flex  w-full',
                    isCoachMsg
                        ? 'justify-start -mt-1'
                        : 'justify-end -mt-3 mb-4',
                )}
            >
                {date}
            </span>
        </div>
    )
}
