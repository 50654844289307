import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressBar } from '../../../components/misc/progress-bar'
import { CenterModal } from '../../../components/modals'
import { useStores } from '../../../utils/stores'
import { Question, QuestionForm } from '../../onboarding/forms'

import { AddRoiQuestionModel } from '../../../models/request/question/roi-question.model'
import {
    RoiQuestion,
    RoiRequestBody,
} from '../../../models/response/questions/roi-questions'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    id: string
}

export const ROIQuetionModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, id }) => {
        const { question } = useStores()

        const { t } = useTranslation()
        const [roiQuestions, setRoiQuestions] = useState<RoiQuestion[]>()
        const [currentQuestion, setCurrentQuestion] = useState<number>(0)
        const [maxQuestion, setMaxQuestions] = useState<number>(10)
        useEffect(() => {
            question.getROIQuestion(id).subscribe({
                next(response) {
                    if (response.ok && response.data) {
                        setMaxQuestions(
                            (response.data as RoiQuestion[])?.length,
                        )
                        setRoiQuestions(response.data)
                    }
                },
            })
        }, [id, question])

        const onPrevious = useCallback(() => {
            if (currentAnswer) {
                setCurrentQuestion(currentQuestion - 1)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentQuestion])

        const saveQuestion = useCallback(
            (values: AddRoiQuestionModel, { resetForm }: any) => {
                resetForm()
                if (roiQuestions && roiQuestions[currentQuestion]) {
                    const questionId = roiQuestions[currentQuestion]._id
                    const requestBody: RoiRequestBody = {
                        groupAnswers: [
                            {
                                questionId,
                                answers: [values?.answer],
                            },
                        ],
                    }
                    question.saveROIQuestion(id, requestBody).subscribe({
                        next(response) {
                            if (response.ok) {
                                setCurrentQuestion(currentQuestion + 1)
                                if (currentQuestion === maxQuestion - 1) {
                                    setIsOpen(false)
                                }
                            }
                        },
                    })
                }
            },
            [
                roiQuestions,
                currentQuestion,
                question,
                id,
                maxQuestion,
                setIsOpen,
            ],
        )

        const getQuestions = useCallback(() => {
            const question = roiQuestions?.[currentQuestion]
            const options: Question[] = []
            if (question?.type === 'radio') {
                return question.answersString.map((option) => {
                    return {
                        key: option,
                        name: option,
                    }
                })
            } else {
                const answersNum = question?.answersNum
                if (answersNum) {
                    const { min, max, step } = answersNum
                    for (let i = min; i <= max; i += step) {
                        options.push({
                            key: `${i}`,
                            name: `${i}`,
                        })
                    }
                }
            }
            return options
        }, [roiQuestions, currentQuestion])

        const currentAnswer = useMemo(() => {
            if (roiQuestions && roiQuestions[currentQuestion]) {
                return {
                    answer: null,
                }
            }
            return null
        }, [roiQuestions, currentQuestion])

        const progress = useMemo(() => {
            return ((currentQuestion + 1) / maxQuestion) * 100
        }, [currentQuestion, maxQuestion])
        const isRating =
            roiQuestions && roiQuestions[currentQuestion].type === 'range'
        return (
            <>
                {isOpen && roiQuestions && (
                    <CenterModal
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        className="max-w-[36rem] h-full overflow-y-auto pt-2"
                        showCloseButton={true}
                    >
                        <div className="h-[200px] px-10 ">
                            {t('questions.outcomeAssessment.title')}
                            <ProgressBar
                                containerClassNames={
                                    'items-center justify-center m-auto w-full mb-5'
                                }
                                barClassNames="h-[8px] "
                                progress={progress}
                            />
                            <QuestionForm
                                title={
                                    roiQuestions &&
                                    roiQuestions[currentQuestion] &&
                                    roiQuestions[currentQuestion].description
                                }
                                rating={isRating}
                                isSlider={isRating}
                                currentNumber={currentQuestion + 1}
                                totalNumber={maxQuestion}
                                questions={getQuestions()}
                                name={'answer'}
                                enableReinitialize={true}
                                model={
                                    new AddRoiQuestionModel(
                                        currentAnswer?.answer ?? null,
                                    )
                                }
                                onSubmit={saveQuestion}
                                showBackArrow={
                                    currentQuestion !== 0 ? true : false
                                }
                                iconClassNames="opacity-20"
                                iconActiveClassNames="opacity-100"
                                onPrevious={onPrevious}
                                className="max-w-none"
                            />
                        </div>
                    </CenterModal>
                )}
            </>
        )
    },
)
