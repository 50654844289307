import { JSONObject, Message, Paginator } from '@twilio/conversations'
import { MediaDetails } from '../models/response/channel'

export const countWords = (str: string) => {
    str = str.replace(/(^\s*)|(\s*$)/gi, '')
    str = str.replace(/[ ]{2,}/gi, ' ')
    str = str.replace(/\n /, '\n')
    return str.split(' ').length
}

export const getMp3 = async (url: string) => {
    const response = await fetch(url)
    const arrayBuffer = await response.arrayBuffer()
    const blob = new Blob([arrayBuffer], { type: 'audio/mp3' })
    return URL.createObjectURL(blob)
}

export const mapMessages = (messages: Paginator<Message>) => {
    return messages.items.map((message) => {
        return mapSingleMessage(message)
    })
}

export const mapSingleMessage = (message: Message) => {
    const attributes = message.attributes as JSONObject
    let mediaDetails = {}
    if (message.type === 'media' && message.attachedMedia) {
        mediaDetails = {
            contentType: message.attachedMedia[0].contentType,
            size: message.attachedMedia[0].size,
            sid: message.attachedMedia[0].sid,
            fileName: message.attachedMedia[0].filename,
            isAudio: attributes?.attachment_type === 'audio_message',
        }
    }

    if (
        ((message.attributes as JSONObject)?.isDeletedForViruses as boolean) &&
        ((message.attributes as JSONObject)?.media as JSONObject)
    ) {
        const mediaArray = (message.attributes as JSONObject)
            ?.media as JSONObject[]
        if (mediaArray.length > 0) {
            const mediaItem = mediaArray[0]
            mediaDetails = {
                contentType: mediaItem.ContentType as string,
                size: mediaItem.Size as number,
                sid: mediaItem.Sid as string,
                fileName: mediaItem.Filename as string,
            }
        }
    }

    return {
        sid: message.sid,
        message: message.body,
        sender: message.author,
        dateCreated: message.dateCreated,
        isMedia: message.type === 'media',
        isUrgent: (attributes?.is_urgent as boolean) || false,
        mediaDetails: mediaDetails as MediaDetails,
        isDeletedForViruses:
            ((message.attributes as JSONObject)
                ?.isDeletedForViruses as boolean) || false,
        checkedForMalware:
            ((message.attributes as JSONObject)
                ?.checkedForMalware as boolean) || false,
    }
}

export const isAllowedAttachmentContentType = (type: string) => {
    return (
        type.search(
            /pdf|application\/msword|application\/vnd.openxmlformats-officedocument.wordprocessingml.document|application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|text\/csv|image\/jpg|image\/jpeg|image\/gif|image\/png|application\/vnd.ms-excel|application\/pdf|text\/plain/i,
        ) >= 0
    )
}

export const getFileSize = (size: number) => {
    const sizeInMB = size / (1024 * 1024)
    if (sizeInMB < 0.1) {
        const sizeInKB = size / 1024
        return `${sizeInKB.toFixed(2)} KB`
    }

    return `${sizeInMB.toFixed(2)} MB`
}
