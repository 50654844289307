import { useField } from 'formik'
import React, { useEffect } from 'react'
import { classNames } from '../../utils/misc'
import { FormValidationMessage } from '../layout/form-validation-message'

interface Props {
    name: string
    label: string
    min: number
    isSlider?: boolean
    max: number
    setStatus?: any
}

export const RateInput: React.FC<Props> = ({
    name,
    label,
    min,
    max,
    setStatus,
}) => {
    const [field, meta, helpers] = useField(name)
    const ratingNumber = Array(max - min + 1).fill(null)

    useEffect(() => {
        if (field.value) {
            setStatus && setStatus('updated')
        }
    }, [field.value, setStatus])

    const onSelect = (value: number) => {
        helpers.setValue(value)
        setStatus && setStatus('updated')
    }

    return (
        <div className="mt-4">
            <span>{label}</span>
            <div className="flex justify-between py-4 px-12 mt-3 rounded-[0.5rem] bg-[#f3f4f4]">
                {ratingNumber.map((_, i) => (
                    <label
                        key={i}
                        htmlFor={name}
                        className={classNames(
                            'cursor-pointer',
                            'block text-[17px]',
                            'h-8 w-8 rounded-full text-center pt-1',
                            field.value === min + i
                                ? 'text-white bg-[#ff7558] '
                                : '',
                        )}
                        onClick={() => onSelect(min + i)}
                    >
                        <input
                            type="radio"
                            checked={field.value === min + i}
                            readOnly
                        />
                        {min + i}
                    </label>
                ))}
            </div>
            {meta.touched && meta.error && (
                <div className="mt-[10px] error">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
