import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import GoalsIcon from '../../assets/icons/my-goals.svg'
import NotesIcon from '../../assets/icons/my-notes.svg'
import TaskIcon from '../../assets/icons/my-tasks.svg'
import SeeAllSession from '../../assets/icons/seeAll.svg'
import { DashboardPageWrapper } from '../../components/navigation'
import { getGreetingKey } from '../../utils/misc/date-time'
import { cancelSubscriptions } from '../../utils/misc/subscriptions'
import { useStores } from '../../utils/stores'
import { Goals } from '../goals/goals'
import { Note } from '../note/note'
import { Task } from '../task/task'
import { AllBookings } from './components/allBookings'
import { AssessmentsView } from './components/assessmentsView'
import { Card } from './components/card'
import { ChecklistCard } from './components/checklistCard'
import { CurrentCoach } from './components/current-coach'
import { UpcomingSessionCard } from './components/upcomingSessionCard'
import { NPSRatingModal } from './modals/nps-rating.modal'
import { RateCoachModal } from './modals/rate-coach.modal'
import { ROIQuetionModal } from './modals/roi-question-modal'

export const DashboardPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { coachee } = useStores()
    const subscriptions: Subscription[] = useMemo(() => [], [])
    const [showRateCoach, setShowRateCoach] = useState(false)
    const [showNPS, setShowNPS] = useState(false)
    const [openTaskList, setOpenTaskList] = useState(false)
    const [openNoteList, setOpenNoteList] = useState(false)
    const [openGoals, setOpenGoals] = useState(false)
    const [openAllBookings, setOpenAllBookings] = useState(false)
    const [questonId, setQuetionId] = useState<string>('')
    const [showQG, setShowQG] = useState(false)

    const getPopups = useCallback(() => {
        const QGRegex = /^QG-\d+.*$/
        const subscription = coachee.getPopups().subscribe({
            next(response) {
                if (response.ok && response.data) {
                    if (response.data.popups.includes('RC')) {
                        setShowRateCoach(true)
                    }
                    if (response.data.popups.includes('NPS')) {
                        setShowNPS(true)
                    }
                    if (
                        response.data?.popups.some((item) => QGRegex.test(item))
                    ) {
                        const id = response.data.popups[0].split('-')[1]
                        setQuetionId(id)
                        setShowQG(true)
                    }
                }
            },
        })

        subscriptions.push(subscription)
    }, [coachee, subscriptions])

    useEffect(() => {
        if (coachee.coachee) {
            getPopups()
        }

        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [coachee.coachee, getPopups, subscriptions])

    useEffect(() => {
        if (coachee) {
            subscriptions.push(coachee.getChecklistEvent().subscribe())

            const getOAStats = coachee.getOAStats()
            if (getOAStats) {
                subscriptions.push(getOAStats.subscribe())
            }
        }

        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [coachee, subscriptions, coachee.coachee])

    return (
        <DashboardPageWrapper
            pageName={t('pageTitles.dashboard')}
            backgroundColour="bg-[#f9fbfc]"
        >
            <main className="mx-auto  px-10  max-w-[1268px] pt-2  py-10 flex flex-col">
                <div className="flex lg:justify-between flex-col lg:flex-row lg:items-center mb-4 lg:mb-6">
                    <span className="text-4xl">
                        {t(getGreetingKey(), {
                            name: coachee.coacheeUser?.firstName,
                        })}
                    </span>
                    <div
                        className="flex items-center space-x-4 cursor-pointer"
                        onClick={() => setOpenAllBookings(true)}
                    >
                        <span className="text-[19px] pt-1 text-[#002b38]">
                            {t('dashboard.seeAll')}
                        </span>
                        <img src={SeeAllSession} />
                    </div>
                </div>
                <div className="flex gap-5 flex-col lg:flex-row">
                    <UpcomingSessionCard />
                    {coachee.coacheeChecklist &&
                        coachee.coacheeChecklist.numberOfIncompleteEvents !==
                            0 && (
                            <ChecklistCard
                                checklistEvents={
                                    coachee.coacheeChecklist.events
                                }
                            />
                        )}
                </div>

                <CurrentCoach />

                <div className="flex flex-col lg:flex-row gap-5">
                    <Card
                        title={t('dashboard.goal')}
                        image={GoalsIcon}
                        onClick={() => setOpenGoals(true)}
                    />
                    <Card
                        title={t('dashboard.task')}
                        image={TaskIcon}
                        onClick={() => setOpenTaskList(true)}
                    />
                    <Card
                        title={t('dashboard.note')}
                        image={NotesIcon}
                        onClick={() => setOpenNoteList(true)}
                    />
                </div>
                <div className="pt-5">
                    <AssessmentsView />
                </div>

                {openNoteList && (
                    <Note
                        isOpen={openNoteList}
                        setIsOpen={() => setOpenNoteList(false)}
                    />
                )}
                {openTaskList && (
                    <Task
                        isOpen={openTaskList}
                        setIsOpen={() => setOpenTaskList(false)}
                    />
                )}
                {openGoals && (
                    <Goals isOpen={openGoals} setIsOpen={setOpenGoals} />
                )}
                {openAllBookings && (
                    <AllBookings
                        isOpen={openAllBookings}
                        setIsOpen={setOpenAllBookings}
                    />
                )}
            </main>
            {showRateCoach && (
                <RateCoachModal
                    isOpen={showRateCoach}
                    setIsOpen={setShowRateCoach}
                />
            )}

            {showNPS && (
                <NPSRatingModal isOpen={showNPS} setIsOpen={setShowNPS} />
            )}
            {showQG && (
                <ROIQuetionModal
                    id={questonId}
                    isOpen={showQG}
                    setIsOpen={setShowQG}
                />
            )}
        </DashboardPageWrapper>
    )
})
