import { ResourceLanguage } from 'i18next'
import { Translation } from '../translation'

export const french: Translation & ResourceLanguage = {
    common: {
        cancel: 'Annuler',
        refresh: 'Actualiser la page',
    },
    ApiError: {
        title: "Une erreur inattendue s'est produite",
        body: `Veuillez réessayer plus tard.\\
Désolé pour la gêne occasionnée.\\
Notre équipe a été informé.`,
    },
    ConfirmLeaving: {
        title: 'Quitter la séance',
        body: 'Êtes-vous certain de vouloir quitter la séance?',
        leave: 'Quitter la séance',
    },
    BookingErrorEarly: {
        title: "La séance n'a pas débuté",
        body: "Vous pouvez joindre la séance jusqu'à 5 minutes avant le début.",
    },
    BookingErrorLate: {
        title: "La séance n'a pas débuté",
        body: "Il n'est plus possible de joindre la séance.",
    },
    Loader: { loading: 'Chargement en cours' },
    MediaPrompt: {
        title: 'Veuillez activer votre caméra',
        body: `Vous devez autoriser le navigateur \\
à accéder votre caméra et microphone`,
    },
    NativeAppPrompt: {
        title: "Les séances vidéo sont meilleures dans l'application Sama",
        body: 'Il est recommandé de faire la séance à partir de votre appli Sama.',
    },
    RecentBrowserPrompt: {
        title: "Votre navigateur de recherche n'est pas supporté",
        body: `Sama n'est pas pris en charge sur votre navigateur. Prière d'utiliser une version à jour de Chrome, Firefox, Safari ou Edge.`,
        procedd: 'Continuer malgré tout',
    },
    RatingPrompt: {
        title: 'Comment noteriez-vous la qualité de votre vidéo?',
        submit: 'Soumettre',
    },
    Session: {
        hideSelf: 'Masquer la vue de soi',
        cameraInuse:
            'Il semble que votre caméra soit utilisée par une autre application.',
    },
    UserMediaError: {
        title: "Nous n'avons pas pu accéder à votre caméra ou microphone",
        body: "Vous devez autoriser l'accès à votre caméra et microphone pour démarrer une session.",
    },
    VoteSubmitted: {
        title: 'Vote soumis',
        body: 'Vous pouvez désormais fermer la fenêtre du navigateur en toute sécurité. Vous serez automatiquement déconnecté.',
    },
    WaitingForCoach: {
        title: 'En attente de votre {{type}} {{name}}',
        body: 'Séance prévue à {{bookingTime}}',
        picAlt: 'photo de profil du {{type}}',
    },
    WaitingSessionMessage: {
        title: 'En attente de votre {{type}} {{name}}',
        body: 'Séance prévue à {{bookingTime}}',
        picAlt: 'photo de profil du {{type}}',
    },
    Settings: {
        title: 'Réglages',
        speakers: 'Haut-parleurs',
        microphone: 'Microphones',
        camera: 'Caméras',
        tooltip: 'Vos paramètres peuvent être changés ici',
        noMicrophone: 'Votre micro est éteint',
    },
    ResetPassword: {
        title: 'Reinitialiser votre mot de passe',
        emailField: '',
        invalidCurrentPassword: `Votre mot de passe n'est pas valide`,
        invalidToken:
            'Jeton invalide, veuillez demander un nouvel e-mail de réinitialisation du mot de passe.',
        noToken:
            'Aucun jeton fourni. Veuillez demander un nouvel e-mail de réinitialisation du mot de passe.',
    },
    pageTitles: {
        logIn: 'Connectez-vous à votre compte',
        resetPassword: 'Reinitialiser votre mot de passe',
        signUp: 'Inscrivez-vous',
        dashboard: 'Tableau de bord',
        reset: '',
        booking: 'Séance',
        newBooking: 'Séance',
        magicLogin: 'Inscription magique',
        onboarding: 'Introduction',
        chat: 'Discussion',
        leaderboard: 'Classement',
        account: 'Compte',
        goals: 'Mes objectifs',
        tasks: 'Mes exercices',
        notes: 'Mes notes',
        logout: 'Déconnecter',
        verifyPassword: 'Vérifiez votre mot de passe',
        loadingPage: 'Chargement',
        twoFa: 'Entrer le mot de passe',
        forgottenPassword: 'Envoyez-moi un nouveau mot de passe',
        redirecting: 'Redirection',
        oktaLogin: 'Connexion Okta',
        oktaCallback: '',
    },
    notifications: {
        slidedown: {
            actionMessage:
                'Restez connecté ! Abonnez-vous pour recevoir des mises à jour de session, des notifications de chat et des nouvelles importantes de Sama.',
            acceptButtonText: '',
            cancelButtonText: 'Plus tard',
        },
    },
    titles: {
        logIn: 'connecter à Sama',
        verifyPassword: 'Vérifiez votre mot de passe',
        setPhone: '{{name}}',
        twoFa: 'Entrez le code que nous avons envoyé au ',
        tc: 'Modalités et conditions',
        twoFaHeader: {
            resend: 'Envoyer SMS au téléphone',
            header: 'Authentification à deux facteurs',
            subHeader: `Code d'identification`,
            content:
                'Lorsque votre téléphone est prêt, cliquez sur le bouton ci-dessous pour recevoir un code par SMS',
        },
    },
    subTitles: {
        appDownload: "OU TÉLÉCHARGER L'APPLICATION",
        welcome: 'Pour débuter suivre le lien.',
        logIn: 'Pour débuter, veuillez vous enregistrer',
        sendTwoFa: 'Cliquez ici pour envoyer le code à: {{phone_number}}',
        twoFaCodeSent: '',
        setPhone:
            'Répondez à ces quelques questions pour débuter votre coaching.',
        tc: `Vos informations restent confidentielles. Nous ne partagerons pas vos informations personnelles avec des tiers.
        Nous promettons que toute conversation que vous avez avec nous demeure confidentielle.Vous pouvez être ouvert et honnête sur ce qui vous tracasse.
        En continuant, vous reconnaissez avoir lu, compris et consentez à nos modalités et conditions et Politique de confidentialité.`,
    },
    fields: {
        email: {
            label: 'Email',
            placeholder: 'Saisissez votre email',
        },
        password: {
            label: 'Mot de passe',
            placeholder: 'Mot de passe',
            validationMessage:
                'Au moins 1 majuscule, Doit contenir un chiffre, Doit contenir un caractère spécial (e.g. "!&#!€!"), Doit contenir au moins 10 caractères.',
        },
        currentPassword: {
            label: 'Mot de passe actuel',
            placeholder: 'Mot de passe actuel',
            validationMessage: '',
        },
        verifyPassword: {
            label: 'Vérifiez votre mot de passe',
            placeholder: 'Vérifiez votre mot de passe',
        },
        phone: {
            label: 'Numéro de téléphone',
            placeholder: 'Numéro de téléphone',
        },
        tc: {
            label: 'J’accepte les <a1>Modalités et conditions</a1> et la <a2>Politique de confidentialité</a2>',
            tcLink: 'https://sama.io/fr/modalites-et-conditions',
            privacyLink: 'https://sama.io/fr/politique-de-confidentialite',
        },
        feedback: {
            label: 'Oh non! Pourriez-vous avoir la gentiellesse de nous dire comment nous améliorer?',
            placeholder: '',
        },
        name: {
            label: 'Nom',
            placeholder: 'Nom',
        },
        goal: {
            label: 'Déterminer votre objectif',
            placeholder: 'Mes objectifs',
        },
        message: {
            placeholder: 'Tappez votre message ici...',
        },
        gender: {
            placeholder: 'Identité de genre',
            label: 'Identité de genre',
        },
        age: {
            label: "Tranche d'âge",
            placeholder: "Tranche d'âge",
        },
        position: {
            label: 'Niveau de séniorité',
            placeholder: 'Niveau de séniorité',
        },
        industry: {
            placeholder: 'Industrie',
            label: 'Industrie',
        },
        function: {
            label: 'Métier',
            placeholder: 'Métier',
        },
        language: {
            label: 'Langue de la plateforme',
            placeholder: 'Langue de la plateforme',
        },
        areaOfCoaching: {
            label: 'Sujet de coaching',
            placeholder: 'Sujet de coaching',
        },
        coachGender: {
            label: 'Préférence pour le coach ',
            placeholder: 'Préférence pour le coach',
        },
        coachingLanguage: {
            label: 'Langue de préférence',
            placeholder: 'Langue de préférence',
        },
        reason: {
            label: 'Autre, ajouter un commentaire',
            placeholder: '',
        },
        newPassword: {
            label: 'Nouveau mot de passe',
            placeholder: 'Nouveau mot de passe',
        },
        retypePassword: {
            label: 'Retaper nouveau mot de passe',
            placeholder: 'Retaper nouveau mot de passe',
        },
        profileImage: {
            label: 'Image de profil',
        },
    },
    modals: {
        checkEmail: {
            heading: 'Vérifiez votre messagerie',
            subText:
                'Confirmer votre adresse dans le courriel envoyé à {{email}}',
        },
        npsRating: {
            header: '',
            title: 'Quelle est la probabilité que vous recommandiez Sama à un ami ou collègue?',
            label: 'Super! Pourriez-vous avoir la gentillesse de nous laisser un commentaire?',
            label2: 'Oh non! Pourriez-vous avoir la gentiellesse de nous dire comment nous améliorer?',
            likely: 'Extrêment probable',
            notLikely: 'Pas du tout probable',
        },
        editGuest: {
            heading: 'Modifier nom',
        },
        removeGuest: {
            heading: 'Révoquer l’invitation {{email}} ?',
        },
        remainingSessions: {
            heading: 'Vous avez {{count}} séances restantes',
            subText:
                'Une séance vaut 25 minutes. Une séance de coaching de 50 minutes équivaut à deux séances.',
            contactUs: "Nous contacter à l'adresse support@sama.io",
        },
        currentCoach: {
            heading: 'Votre coach',
        },
        coachProfile: {
            canCoach: 'Coach sur les domaines suivants',
            certifications: 'Certifications de coaching',
            memberships: 'Adhésions',
            timezone: 'Fuseau horaire',
        },
        booking: {
            heading: 'Êtes vous sûr de vouloir sélectionner cet horaire ?',
            subText: 'Il semble un peu {{period}}',
            early: 'tôt',
            late: 'tard',
        },
        rateCoach: {
            title: '',
            subTitle: 'Êtes-vous satisfait de votre coach',
            rate: '{{rate}} etoiles',
        },
        notes: {
            createNote: 'Créer une note',
            editNote: 'Éditer note',
        },
        guest: {
            checkGuest: 'Avez-vous vérifié leur disponibilité ?',
            checkCoach:
                "Avez-vous discuté avec votre coach votre intention d'inviter votre collègue?",
            yes: 'Oui',
            no: 'Non',
            addColleague: 'Inviter collègue',
            heading: 'Inviter',
            subText: 'Inviter ({{number}})',
        },

        uploadProfileImage: {
            heading: 'Télécharger logo',
            subText: "La taille de l'image doit être de 500 x 500 pixels.",
            browse: 'parcourir',
            dragAndDrop: "Insérer l'image, ou <0>{{browse}}</0>",
            supportedFileTypes: 'Prend en charge .jpg, .png ou .svg',
        },
        removeAccount: {
            heading:
                'Si vous supprimez vos données, vous supprimerez votre compte. Souhaitez-vous continuer?',
        },
        removeBooking: {
            heading: 'Êtes-vous certain de vouloir annuler cette séance?',
            textHeader: '',
            mainMessage:
                'Puisque votre séance est prévue dans moins de 24 heures. Votre employeur devra payer pour cette séance.',
            lateCancelHeading: 'Désirez-vous toujours annuler la séance?',
        },
        logOut: {
            heading: 'Voulez-vous vous deconnecter ?',
        },
        rescheduleBooking: {
            heading: 'Voulez vous toujours rescheduler la séance?',
            mainMessage:
                'Puisque votre séance est prévue dans moins de 24 heures. Votre employeur devra payer pour cette séance.',
        },
    },
    login: {
        forgotPassword: 'Vous avez oublié votre mot de passe',
        reset: '<0>Reinitialiser votre mot de passe</0>',
    },
    onboarding: {
        question: 'Question {{number}} sur {{total}}',
        areaOfCoaching: {
            title: 'Sujet de coaching',
            subTitle: 'Sujet de coaching désiré',
            quizText:
                'Vous ne savez pas par où commencer avec le coaching? <0>Répondez à notre quiz de 3 minutes<0>',
            quizLink:
                'https://sama.io/quel-sujet-de-coaching-choisir/?userId={{userId}}',
        },
        gender: {
            title: 'Quelle est votre identité de genre?',
        },
        genderSpecified: {
            title: `Veuillez préciser`,
            placeholder: 'Veuillez préciser',
        },
        ageRange: {
            title: 'Quelle est votre tranche d’âge?',
        },
        industry: {
            title: 'Quelle est votre industrie?',
        },
        function: {
            title: 'Quel est votre métier? ',
        },
        position: {
            title: 'Quelle est votre fonction?',
        },
        rateSatifaction: {
            title: 'Votre satisfaction dans les secteurs suivants',
            subTitle: '1 = Peu satisfait ; 5 = Très satisfait',
        },
        topValues: {
            title: '5 valeurs prioritaires',
            subTitle: 'Choisissez vos 5 valeurs les plus importantes.',
        },
        coachGender: {
            title: 'Préférez-vous que votre coach soit',
        },
        langauge: {
            title: 'Langue de coaching',
            subTitle: 'Préférence de langue du coach.',
        },
        findCoach: {
            title: 'Nous cherchons votre coach',
            foundTitle: 'Votre coach',
            foundSubTitle: 'En fonction de vos critères',
            findingSubTitles: [
                'Le meilleur coach pour vous',
                'Algorithme de mise en relation soutenue par la science',
                'Accélérez votre carrière',
                'Un vie riche de sens',
                'Nous avons trouvé votre coach!',
            ],
        },
        initial: {
            title: 'Mieux vous connaître',
            title2: '',
            subTitle:
                'Pour vous mettre en relation avec le meilleur coach pour vous, nous aimerions vous poser quelques questions.',
        },
    },
    buttons: {
        continue: 'Continuer',
        okta: '',
        next: 'Suivant',
        save: 'Enregistrer',
        usePassword: 'Utilisez le mot de passe pour vous connecter',
        resend2fa: 'Renvoyer le code',
        bookSession: 'Réserver votre séance',
        close: 'Fermer',
        contactUs: 'Nous contacter',
        joinSession: 'Joindre la séance',
        chat: 'Discussion',
        profile: 'Voir profil',
        rate: 'Envoyer',
        delete: 'Supprimer',
        cancel: 'Annuler',
        explore: 'Explorez Sama',
        upload: 'Télécharger',
        edit: 'Éditer',
        remove: 'Retirer',
        deleteAccount: 'Fermer le compte',
        cancelSession: 'Annuler',
        keepSession: 'Non',
        logOut: 'Déconnecter',
        stayLogin: 'Non',
        microsoft: 'Microsoft',
        verify: 'Vérifiez',
        bookLateSession: 'Réserver',
        rescheduleSession: 'Oui, reschéduler la séance',
    },
    messages: {
        loading: 'Chargement',
        emailSent: `Email envoyé. Vous ne le trouvez pas ? Veuillez vérifier votre dossier spam.`,
        passwordReset: 'Votre mot de passe a été modifié!',
        resend2fa: `Vous n'avez pas reçu de SMS ? Envoyer à nouveau (dans {{seconds}}s).`,
        canResend2fa: `Envoyer SMS au téléphone`,
        tooManyRequests:
            'Trop de tentatives, veuillez attendre 15 minutes et réessayer.',
        invalidToken: "Le jeton n'est pas valide",
        remainingSessions: '{{count}} séances restantes',
        sessionBooked: 'Votre séance est réservée',
        inviteGuest: `Veuillez vérifier la disponibilité et l'accord des participants avant d'inviter votre collègue.`,
        inviteGuestCoach: `Veuillez vérifier la disponibilité et l'accord des participants avant d'inviter votre collègue.`,
        urgentMessage: 'Votre message a été marqué comme urgent.',
        addedGuest:
            '{{name}} ({{email}}) a été invité à votre session le {{date}}.',
        languageChanged: 'La langue a été mise à jour',
        infectedFile:
            "Ce fichier n'a pas pu être envoyé car il semble contenir des risques potentiels pour la sécurité. Vous pouvez consulter votre service TI pour obtenir de l'aide.",
    },
    validation: {
        invalidFormat: 'Format non valide',
        invalidEmail: "L'adresse email est erronée",
        invalidPhone: 'Format requis: +15142359999',
        checkEmailOrPassword:
            'Veuillez vérifier votre courriel ou mot de passe.',
        mustBeSet: 'Ajouter',
        thereIsAnError: 'Il y a une erreur ci-dessous.',
        passwordsDoNotMatch: 'Les mots de passe saisis ne sont pas les mêmes',
        upperCase: 'Au moins 1 majuscule',
        containsNumber: 'Doit contenir un chiffre',
        specialCharacter: 'Doit contenir un caractère spécial (e.g. "!&#!€!")',
        minimumLength: 'Doit contenir au moins 10 caractères',
    },
    alt: {
        logo: 'Logo',
        arrowLeft: 'Précédent',
        checked: 'Checked',
        onboarding: 'Introduction',
        video: 'Icône vidéo',
        clock: 'Icône de durée',
        rating: 'Icône d’évaluation',
        typing: '',
        profile: '',
        upcomingSession: '',
    },
    or: 'ou',
    questions: {
        outcomeAssessment: {
            title: 'Progrès',
            answers: {
                peak: 'Optimal',
                strong: 'Moyen',
                good: 'Bien',
                moderate: 'Fort',
                low: 'Faible',
            },
        },
    },
    dashboard: {
        greetingMorning: 'Bonjour {{name}},',
        greetingAfternoon: 'Bonjour {{name}},',
        greetingEvening: 'Bonsoir {{name}},',
        milestone: '',
        goal: 'Mes objectifs',
        task: 'Mes exercices',
        note: 'Mes notes',
        seeAll: 'Voir toutes séances futures',
        bookings: 'Prochaines séances',
        session: {
            coachingSession: 'Séance de coaching',
            noSession:
                'Votre coach vous attend. Réservez votre séance aujourd’hui.',
            todaySession: `Aujourd'hui, {{time}}`,
            video: 'Vidéo',
            audio: 'Audio',
            duration: '{{mins}} min',
            cancel: 'Annuler',
            reschedule: 'Modifier',
            guest: 'Invité',
            sessionSoon: 'Débute dans {{min}} mins',
            sessionStarted: 'La séance est en cours',
            guestLength: 'Invité ({{length}})',
            noUpcomingSession: 'Vous n’avez pas encore réservé de séances',
        },
        checklist: {
            title: 'Et maintenant ?',
        },
    },
    others: {
        seeAll: 'Tout voir',
        remove: 'Retirer',
        skip: 'Sauter',
        charatersLeft: '{{count}} caractères restants',
    },
    booking: {
        selectDate: 'Sélectionnez une date',
        selectDuration: 'Sélectionnez durée de la séance',
        selectTime: 'Sélectionnez le début',
        selectType: 'Sélectionnez le type de séance',
        inviteGuests: 'Inviter collègue',
        minutes25: '25 min',
        minutes50: '50 min',
        timezone: 'Dans votre fuseau horaire, {{timezone}}',
        video: 'Vidéo',
        call: 'Audio',
        bookSession: 'Réserver votre séance',
        addColleague: 'Inviter collègue',
        nextMonth: 'Suivant mois',
        prevMonth: '',
        monday: 'LUN',
        tuesday: 'MAR',
        wednesday: 'MER',
        thursday: 'JEU',
        friday: 'VEN',
        saturday: 'SAM',
        sunday: 'DIM',
        lateBooking: 'Cette séance n’est plus disponible',
        maxNumOfBookings: 'Vous ne pouvez réserver plus de 6 séances à la fois',
        noChanges: 'Erreur! Aucun changement fait. Prière de modifier un item.',
    },
    assessments: {
        selfAssessment: {
            title: 'Connaissez vos forces',
            subtitle:
                'Bien joué! Restez aux aguets pour les prochaines questions.',
            subtitleIncomplete: 'Prendre conscience de soi',
        },
        satisfactionLevels: {
            title: 'Perspective',
            subtitle: 'Votre niveau de satisfaction',
        },
        topValues: {
            title: 'Perspective',
            subtitle: 'Vos principales valeurs',
        },
        banner: {
            title: 'Sama sur votre mobile',
            subtitle:
                "Sama sur la route. Téléchargez l'application sur l'AppStore ou Google Play.",
            googleLink:
                'https://play.google.com/store/apps/details?id=io.sama.coachee&hl=fr',
            appleLink:
                'https://apps.apple.com/fr/app/sama-professional/id1520645244',
        },
    },
    note: {
        title: 'Mes notes',
        subtitle: 'Confidentielles et seulement visibles par vous.',
        addNote: 'Créer note',
        createNote: 'Créer note',
        noNote: 'Vous n’avez pas de notes.',
        noNoteDesc: '',
    },
    task: {
        title: 'Mes exercices',
        noTask: "Pas d'exercices",
        noTaskDesc: 'Votre coach ne vous a pas donné d’exercices.',
        today: "Aujourd'hui",
    },
    goals: {
        title: 'Mes objectifs',
        subtitle: 'Votre coach peut voir vos objectifs',
        addGoal: 'Ajouter un objectif',
        editGoal: 'Modifier',
        smartGoals: 'Vos objectifs doivent être',
        dueDate: 'À compléter avant le {{date}}',
        due: 'Date de complétion',
        overdue: 'Date de complétion passée {{date}}',
        completedAt: 'Complété le {{date}}',
        noDueDate: 'Aucune échéance',
        noGoals: 'Aucun objectif ajouté',
        noActiveGoals: 'Aucun objectif ajouté',
        noCompleteGoals: 'Aucun objectif encore complété',
        trackProgress: 'Suivre votre progression',
        nearlyDone: 'Presque complété',
        notStarted: 'Pas encore débuté',
        halfWay: 'Déjà à moitié complété!',
        started: 'En cours',
        done: 'Complété! Bravo!',
        selectDueDate: `Fixer l'échéance`,
        tabs: {
            all: 'Tous',
            active: 'En vigueur',
            completed: 'Complétés',
        },
        smartGoalList: {
            specific: 'Spécifiques',
            measurable: 'Mesurables',
            achievable: 'Réalisables',
            relevant: 'Pertinents',
            time: 'Limités dans le temps',
        },
    },
    leaderboard: {
        totalSessions: 'Séances',
        completedTasks: 'Exercices complétés',
        missedSessions: 'Séances Manquées',
        checkIns: 'Check-ins',
        trends: 'Évolution',
        about: 'Info',
        rating: 'Echelle',
        levels: 'Niveau',
        noTotalSessions: 'Aucune complétée',
        noTotalSessionsDesc: '<0>Réservez maintenant!<0>',
        completedTasksDesc: `Vous n'avez pas de tâches complétées`,
        tasksCompleted: 'Exercices complétés',
        month: 'MOIS',
        completed: 'COMPLETÉS',
        aboutTracker: 'À propos de votre progrès',
        aboutTrackerDesc: `Suivez votre progrès sur chaque moteur d'engagement`,
        peak: 'Optimal',
        strong: 'Moyen',
        good: 'Bien',
        moderate: 'Fort',
        low: 'Faible',
        missedSessionsDesc: `Vous n'avez pas manqué de séances. Bravo!`,
        missedSessionsInfo: `Votre employeur est facturé lorsque vous annulez ou reportez un rendez-vous dans les 24 heures`,
        checkInsDesc: 'Aucun complété',
        level: 'Niveau',
        cancel: 'Annuler',
        tracker: {
            resilience: 'Résilience',
            adaptability: 'Adaptabilité',
            'self-efficacy': 'Confiance en soi',
            alignment: 'Alignement',
            motivation: 'Motivation',
        },
    },
    chat: {
        emptyChat:
            'Les messages avec votre coach sont confidentiels et sécurisés.',
        voiceMessage: 'Message vocal',
        coachTime: 'Son heure locale est: {{time}}',
        fileLoader: 'Téléchargement... {{filename}}',
        wrongFileFormat:
            "Veuillez télécharger l'un des types de fichiers suivants : pdf, docx, csv, jpg, jpeg, gif, png, xlsx, txt.",
        cantReply: 'Vous ne pouvez pas continuer cette discussion',
    },
    account: {
        personal: 'Personnel',
        coachingPreference: 'Préférence de coaching',
        account: 'Compte',
        infoSupport: 'Info & Support',
        myCoach: 'Mon coach',
        changeCoach: 'Changer de coach',
        changeCoachDesc:
            "Si vous souhaitez être matché à nouveau avec ce coach plus tard, merci de nous contacter à l'adresse support@sama.io",
        changeReasonQuestion:
            'Pour quelle raison souhaitez-vous changer de coach ?',
        delete: '',
        chatHistory: 'Historique des Discussions',
        misson: 'Notre mission',
        infoSupportPage: {
            about: 'À propos de Sama',
            faq: 'FAQ',
            contactUs: 'Contactez-nous',
            terms: 'Modalités et conditions',
            privacy: 'Politique de confidentialité',
            aboutText:
                'Chez Sama, nous démocratisons le coaching pour accroître la motivation de tous et ainsi contribuer à un monde meilleur.\n\nNous vous mettons en relation avec un coach choisi spécialement pour vous, en fonction du sujet sur lequel vous souhaitez avancer et selon des critères personnalisés comme votre niveau d’expérience ou votre secteur d’activité.\n\nNos coachs sont rigoureusement sélectionnés pour s’assurer qu’ils ont les meilleurs qualifications et expériences pour vous guider au mieux dans votre carrière et dans votre vie.\n\nUne fois que nous vous avons trouvé un coach sur-mesure, vous pouvez commencer les séances au travers l’App, par chat ou appel vidéo ou audio.\n\nLes services de Sama sont payés par votre employeur mais toutes vos interactions avec Sama resteront strictement confidentielles, comme nous y engage notre <0>Politique de confidentialité.<0>',
            termsText: '',
            privacyLink: 'https://sama.io/fr/politique-de-confidentialite',
            termsLink: 'https://sama.io/fr/modalites-et-conditions',
            contactUsLink: 'support@sama.io',
        },
    },
}
