import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import appleStoreImgFr from '../../../assets/icons/app-store-fr.png'
import appleStoreImgEn from '../../../assets/icons/apple-store.svg'
import googlePlayStoreImgFr from '../../../assets/icons/google-playstore-fr.svg'
import googlePlayStoreImgEn from '../../../assets/icons/google-playstore.svg'

import Logo from '../../../assets/logo/logo-dark.svg'
import { MagicLoginModel } from '../../../models/request'
import { NotificationType, RouteLink } from '../../../utils/constants'
import { classNames } from '../../../utils/misc'
import { stores, useStores } from '../../../utils/stores'

export const MagicSignupPage: React.FC = observer(() => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const token = search.get('magicToken')
    const { auth, notifications, coachee } = useStores()
    const language = i18n.language
    const playStoreAppLink =
        language === 'en'
            ? import.meta.env.VITE_APP_EN_PLAY_STORE_URL
            : import.meta.env.VITE_APP_FR_PLAY_STORE_URL

    const appStoreAppLink =
        language === 'en'
            ? import.meta.env.VITE_APP_EN_APP_STORE_URL
            : import.meta.env.VITE_APP_FR_APP_STORE_URL

    const googlePlayImg =
        language === 'en' ? googlePlayStoreImgEn : googlePlayStoreImgFr

    const appleStoreImg = language === 'en' ? appleStoreImgEn : appleStoreImgFr

    const logIn = useCallback(() => {
        if (!token) {
            return navigate(RouteLink.INDEX, { replace: true })
        }

        auth.MagicLogIn(new MagicLoginModel(token)).subscribe({
            next(response) {
                if (response.ok) {
                    if (response.data) {
                        auth.setAuthResponse(response.data)
                        const data = response.data
                        if (!data.isPhoneNeeded && data.awaiting2fa) {
                            return navigate(RouteLink.TWO_FA, {
                                replace: true,
                            })
                        }

                        if (data.isPhoneNeeded) {
                            return navigate(RouteLink.SIGN_UP, {
                                replace: true,
                                state: {
                                    canSignUp: true,
                                },
                            })
                        }

                        return coachee.getCoachee().subscribe({
                            next(response) {
                                if (
                                    response.ok &&
                                    coachee.coachee?.currentCoach._id
                                ) {
                                    return navigate(RouteLink.DASHBOARD, {
                                        replace: true,
                                    })
                                } else {
                                    return navigate(RouteLink.SIGN_UP, {
                                        replace: true,
                                        state: {
                                            canSignUp: true,
                                        },
                                    })
                                }
                            },
                        })
                    }
                }

                notifications.createNotification(
                    NotificationType.ERROR,
                    t('messages.invalidToken'),
                    5 * 1000 /* 5 seconds */,
                )
                stores.reset()
                navigate(RouteLink.LOG_IN, { replace: true })
            },
        })
    }, [auth, coachee, navigate, notifications, t, token])

    useEffect(() => {
        if (search.has('do_login')) {
            logIn()
        }
    }, [auth, coachee, logIn, notifications, search])
    return token ? (
        <>
            <main className="flex flex-col items-center justify-center h-screen w-full">
                <div
                    className={classNames(
                        'lg:w-[550px] px-8 h-[450px]',
                        'flex flex-col items-center justify-center bg-[#f9fbfc]',
                    )}
                >
                    <img src={Logo} width={80} alt={t('alt.logo')} />
                    <h3 className="lg:text-[2rem] leading-[40px] text-center text-[1.5rem] mt-3">
                        {t('titles.logIn')}
                    </h3>
                    <span className="text-[15px] -mt-2 text-primary">
                        {t('subTitles.welcome')}
                    </span>
                    <a
                        href={window.location.href + '&do_login=true'}
                        className={classNames(
                            'px-[48px] mt-6 mb-10 py-3',
                            'rounded-full',
                            'bg-primary active:bg-accent-2',
                            'disabled:bg-[#f9fbfc]',
                            'disabled:text-[#a5b2b6] text-[#ffffff] text-[17px]',
                            'h-[50px] text-center w-[80%] lg:w-[380px]',
                        )}
                    >
                        {t('buttons.continue')}
                    </a>
                    <span className="text-[12px] font-[600] uppercase  text-primary">
                        {t('subTitles.appDownload')}
                    </span>
                    <div className="flex space-x-2 mt-3 items-center">
                        <a target="_blank" href={encodeURI(appStoreAppLink)}>
                            <img
                                src={appleStoreImg}
                                alt="apply-playtore"
                                width={150}
                            />
                        </a>
                        <a href={encodeURI(playStoreAppLink)} target="_blank">
                            <img
                                src={googlePlayImg}
                                alt="google-playStore"
                                width={150}
                            />
                        </a>
                    </div>
                </div>
            </main>
        </>
    ) : null
})

export default MagicSignupPage
